var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.schedule)?_c('v-dialog',{attrs:{"overlay-color":"#fff","overlay-opacity":"1","max-width":"780","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"persistent":"","no-click-animation":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('img',{staticClass:"video-logo",attrs:{"src":_vm.$vuetify.theme.options.logo,"alt":"Adhere"}}),(_vm.videoType === 'youtube-video-show')?_c('YoutubeVideo',{attrs:{"params":{
          nextBtnLabel: false,
          videos: _vm.videos
        }},on:{"stats":_vm.track}}):(_vm.videoType === 'audio-show')?_c('Audio',{attrs:{"params":{
          nextBtnLabel: false,
          audios: _vm.audios
        }},on:{"stats":_vm.track}}):(_vm.videoType === 'video-show')?_c('Video',{attrs:{"params":{
          duration: _vm.duration,
          nextBtnLabel: false,
          videoFiles: _vm.videoFiles || _vm.schedule.videoFiles,
          selected: _vm.videoType
        },"duration":_vm.schedule.duration},on:{"stats":_vm.track}}):_c('relaxation-choose',{attrs:{"params":{
          duration: _vm.duration,
          nextBtnLabel: false,
          selected: _vm.videoType
        },"duration":_vm.schedule.duration},on:{"stats":_vm.track}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }