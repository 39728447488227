<template>
  <!-- We're going to force this modal to be fullscreen, since the user couldn't click out of it anyway! -->
  <v-dialog
    v-if="schedule"
    v-model="dialog"
    overlay-color="#fff"
    overlay-opacity="1"
    max-width="780"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    no-click-animation
  >
    <v-card>
      <v-card-text>
        <img
          :src="$vuetify.theme.options.logo"
          alt="Adhere"
          class="video-logo"
        >
        <!-- If it's a relaxation video -->
        <YoutubeVideo
          v-if="videoType === 'youtube-video-show'"
          :params="{
            nextBtnLabel: false,
            videos: videos
          }"
          @stats="track"
        />
        <!-- If it's a relaxation audio -->
        <Audio
          v-else-if="videoType === 'audio-show'"
          :params="{
            nextBtnLabel: false,
            audios: audios
          }"
          @stats="track"
        />
        <!-- If it's video-show -->
        <Video
          v-else-if="videoType === 'video-show'"
          :params="{
            duration: duration,
            nextBtnLabel: false,
            videoFiles: videoFiles || schedule.videoFiles,
            selected: videoType
          }"
          :duration="schedule.duration"
          @stats="track"
        />
        <!-- Else (looks like video is the last one) -->
        <relaxation-choose
          v-else
          :params="{
            duration: duration,
            nextBtnLabel: false,
            selected: videoType
          }"
          :duration="schedule.duration"
          @stats="track"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import RelaxationChoose from '@/kit/controls/RelaxationChoose'
import YoutubeVideo from '@/kit/controls/YoutubeVideo'
import Audio from '@/kit/controls/Audio'
import Video from '@/kit/controls/Video'

export default {
  components: {
    RelaxationChoose,
    YoutubeVideo,
    Audio,
    Video
  },
  data() {
    return {
      dialog: true,
      type: 'relaxation',
      videoType: this.$route.params.type,
      notificationId: this.$route.params.id,
      schedule: null,
      duration: null,
      videos: [],
      audios: [],
      videoFiles: [],
      completed: {},
      session: null,
      completeOn: 70
    }
  },
  async created() {
    const schedule = await this.$firebase.functions.httpsCallable(
      'getRelaxation'
    )({
      key: this.$route.params.id
    })

    if (null != schedule.data) {
      this.schedule = schedule.data
      this.duration = this.schedule.duration
      const videoId = this.schedule?.video?.id
      const videoFiles = this.schedule?.videos?.videoFiles
      const audioId = this.schedule?.audio?.id
      if (videoId !== undefined && !videoFiles?.length) {
        this.videos = this.schedule?.videos?.videos.map(v => ({
          ...v,
          selected: v.id === videoId
        }))
      }
      if (undefined !== audioId) {
        this.audios = this.schedule?.audios?.audios.map(a => ({
          ...a,
          selected: a.id === audioId
        }))
      }

      if (undefined !== videoFiles) {
        // videoFiles is array of objs: { src, type (which is a mime type string) }
        // See getRelaxation.ts for reference
        this.videoFiles = this.schedule?.video?.videoFiles
      }

      await this.sessionStart(this)
    } else {
      this.$router.push({ name: 'signIn' })
    }
  },
  head: {
    title: {
      inner: 'Relaxation'
    },
    meta: [
      {
        name: 'description',
        content: 'Relaxation',
        id: 'desc'
      }
    ]
  },
  methods: {
    ...mapActions('schedules', ['sessionStart', 'sessionProgress']),
    async track($event) {
      await this.$emit('track', $event)
    }
  }
}
</script>

<style lang="scss">
.video-logo {
  width: 36px;
  margin: 0 auto 0 auto;
  display: block;
  padding: 12px 0;
}
.relaxation-timer {
  margin-top: 2px;
  margin-bottom: 2px;
}
.relaxation-select {
  margin: 0 auto !important;
}
.relaxation-container {
  background: #ffffff;
}
// .disable-dialog-transition {
//   .v-overlay, .v-dialog {
//     -webkit-transition-property: none !important;
//     -moz-transition-property: none !important;
//     -o-transition-property: none !important;
//     transition-property: none !important;
//   }
// }
</style>
